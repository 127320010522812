<template>
  <div id="descriptor-analytics">
    <v-card>
      <v-card-title>
        Descriptor Analytics
      </v-card-title>
      <v-row  class="px-2 ma-0">
        <v-col cols="12" sm="12">

          <v-file-input
            v-model="uploadedFile"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            label="Select a file..."
            :loading="uploadedFileDescriptor"
            :disabled="uploadedFileDescriptor"
            dense
            outlined
          ></v-file-input>
        </v-col>
        <v-col v-if="uploadedFileDescriptorError" cols="12" sm="12">
          <v-alert color="error" dark>
            {{ uploadedFileDescriptorError }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row class="px-2 ma-0" v-if="uploadedFileDescriptorResult">
        <v-divider class="mt-4 mb-4"></v-divider>
        <v-col cols="12" sm="12" v-if="uploadedFileDescriptorResult.length">
          <v-row>
            <v-col cols="6" sm="6">
              Descriptors missing in Merchanto
            </v-col>
            <v-col cols="12" sm="12">
              <div>
                <v-data-table :items="uploadedFileDescriptorResult" :headers="headers">
                  <template #[`item.action`]="{ item, index }">
                    <div>
                      <v-btn color="primary" class="me-3 mark-known-button" @click="markKnown(item, index)"> Mark Acknowledge </v-btn>
                    </div>
                  </template>
                  <template #[`item.website`]="{ item }">
                    <div v-if="item.website">{{ item.website.name }}</div>
                  </template>
                </v-data-table>
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" v-if="!uploadedFileDescriptorResult.length">
          <v-alert color="success" dark>
            All Descriptors match
          </v-alert>
        </v-col>

      </v-row>
    </v-card>
  </div>
</template>

<script>
import useDescriptorAnalytics from '@/views/analytics/descriptor-analytics/useDescriptorAnalytics';

export default {
  name: 'DescriptorAnalytics',
  setup() {
    const {
      uploadedFile,
      uploadedFileDescriptor,
      uploadedFileDescriptorResult,
      uploadedFileDescriptorError,
      headers,
      markKnown,
    } = useDescriptorAnalytics();

    return {
      uploadedFile,
      uploadedFileDescriptor,
      uploadedFileDescriptorResult,
      uploadedFileDescriptorError,
      headers,
      markKnown,
    };
  },
};
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.mark-known-button {
  font-size: 10px!important;
  padding: 10px!important;
}
</style>
