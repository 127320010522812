import { ref, watch } from '@vue/composition-api';
import store from '@/store';

export default function useDescriptorAnalytics() {

  const uploadedFile = ref(null);
  const uploadedFileDescriptor = ref(false);
  const uploadedFileDescriptorResult = ref(null);
  const uploadedFileDescriptorError = ref(null);

  const headers = [
    { text: 'Merchant', value: 'merchant' },
    { text: 'Match type', value: 'match_type' },
    { text: 'Match criterion', value: 'match_criterion' },
    { text: 'Matched descriptor', value: 'matched_descriptor' },
    { text: 'Match parameter', value: 'match_parameter' },
    { text: 'Updated date/time', value: 'date' },
    { text: 'Status', value: 'status' },
    { text: 'Website', value: 'website' },
    { text: 'Action', value: 'action' }
  ];

  const markKnown = (item, descIndex) => {
    store
      .dispatch('chargebackEvent/markKnown', {
        ...item,
      })
      .then(
        uploadedFileDescriptorResult.value = uploadedFileDescriptorResult.value.filter((item, index) => index !== descIndex)
      );
  };

  watch(uploadedFile, () => {
    if (!uploadedFile.value) {
      return;
    }

    uploadedFileDescriptor.value = true;
    uploadedFileDescriptorResult.value = null;
    uploadedFileDescriptorError.value = null;

    let formData = new FormData();
    formData.append('file', uploadedFile.value);

    store
      .dispatch('chargebackEvent/descriptorAnalytics', formData)
      .then(response => {
        uploadedFileDescriptorResult.value = response.data.cases;
      })
      .catch(() => {
    uploadedFileDescriptorError.value = 'Can not parse file';
      })
      .finally(() => {
        uploadedFileDescriptor.value = false;
        uploadedFile.value = null;
      });
  });
  return {
    uploadedFile,
    uploadedFileDescriptor,
    uploadedFileDescriptorResult,
    uploadedFileDescriptorError,
    headers,
    markKnown,
  };
}
